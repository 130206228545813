
import { defineComponent, onMounted, computed } from "vue";
import { useContent } from "../../composables/useContent";
import SmallPlayer from "@/components/Player/SmallPlayer.vue";
import GalleryCard from "@/components/Gallery/GalleryCard.vue";
import { getHeaderData } from "@/components/SEO/helpers";
import { useHead } from "@vueuse/head";
import Markdown from "vue3-markdown-it";
import { GENERAL_FEEDBACK_URL } from "@/constants/feedback";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "HomePage",
  components: {
    SmallPlayer,
    GalleryCard,
    Markdown,
  },
  setup() {
    const { load, result } = useContent();

    const route = useRoute();

    const home = computed(() =>
      result.value?.id === "home" ? result.value : undefined
    );

    const seo = computed(() => home.value?.seo);

    const galleries = computed(() => home.value?.galleryCollection?.items);

    const aboutPages = computed(
      () => home.value?.homeAboutGalleryCollection?.items
    );

    const comingSoon = computed(
      () => home.value?.comingSoonGalleryCollection?.items
    );

    const primer = computed(() => home.value?.introAudio);

    const headerData = computed(() =>
      getHeaderData(seo.value, { url: window.location.href })
    );

    onMounted(async () => {
      await load({ contentType: "home", slug: "" });
      if (route?.hash === "#explore") {
        document.getElementById("start-button")?.click();
      }
    });

    useHead({
      title: computed(() => headerData.value?.title || "Illume"),
      meta: computed(() => headerData.value?.meta || []),
      link: computed(() => headerData.value?.link || []),
    });

    return {
      home,
      primer,
      galleries,
      aboutPages,
      comingSoon,
      GENERAL_FEEDBACK_URL,
    };
  },
});

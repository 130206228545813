
import { defineComponent, PropType, computed } from "vue";
import { GalleryProps } from "../../../composables/types";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "GalleryCard",
  props: {
    gallery: Object as PropType<GalleryProps>,
    type: String,
  },
  setup(props) {
    const router = useRouter();

    const slug = computed(() => `/gallery/${props.gallery?.slug}`);

    const pushToGallery = () => {
      router.push({ path: slug.value });
    };

    const totalSegments = computed(
      () => props.gallery?.segmentsCollection?.total
    );

    const totalAuthors = computed(
      () => props.gallery?.authorsCollection?.total
    );

    const stickerText = computed(() => {
      if (props.type === "about") {
        return "LEARN";
      }
      return props.gallery?.location;
    });

    const segmentTitle = computed(() =>
      totalSegments.value === 1 ? "Segment" : "Segments"
    );

    const guideName = computed(() =>
      totalAuthors.value === 1 ? "Guide" : "Guides"
    );

    return {
      pushToGallery,
      totalSegments,
      totalAuthors,
      stickerText,
      slug,
      guideName,
      segmentTitle,
    };
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, ref, Ref } from "vue";
import { selectQuery } from "./helpers";
import { SegmentProps, GalleryProps, HomeProps } from "./types";

type UseContentLoad = { contentType: string; slug?: string | string[] };

export const useContent = () => {
  const result: Ref<SegmentProps | GalleryProps | HomeProps | null> = ref(null);
  const loading: Ref<boolean> = ref(false);
  const error: any = ref({ load: null });

  const spaceId = process.env.VUE_APP_CONTENT_SPACE_ID;
  const deliveryToken = process.env.VUE_APP_CONTENT_DELIVERY_TOKEN;

  const load = async ({ contentType, slug }: UseContentLoad) => {
    try {
      loading.value = true;
      const query = selectQuery(contentType, slug);
      const url = `https://graphql.contentful.com/content/v1/spaces/${spaceId}/environments/master`;

      const fetchResponse = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/JSON",
          authorization: `Bearer ${deliveryToken}`,
        },
        body: JSON.stringify({
          query,
        }),
      });

      const response = await fetchResponse.json();
      result.value = {
        id: contentType,
        ...response?.data?.[`${contentType}Collection`]?.items[0],
      };
      error.value.load = null;
    } catch (err) {
      console.log("$$ error: ", err);
      error.value.load = err;
    } finally {
      loading.value = false;
    }
  };

  return {
    load,
    result: computed(() => result.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
  };
};


import { defineComponent, onMounted, computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useContent } from "../../composables/useContent";
import LargePlayer from "../components/Player/LargePlayer.vue";
import { getHeaderData } from "@/components/SEO/helpers";
import { useHead } from "@vueuse/head";
import CustomModal from "@/components/Modal/CustomModal.vue";
import Markdown from "vue3-markdown-it";

export default defineComponent({
  name: "SegmentPage",
  components: {
    LargePlayer,
    CustomModal,
    Markdown,
  },
  setup() {
    const route = useRoute();
    const { load, result } = useContent();

    const segment = computed(() =>
      result.value?.id === "segment" ? result.value : undefined
    );

    const seo = computed(() => segment.value?.seo);
    const artist = computed(() => segment.value?.artist);
    const audio = computed(() => segment.value?.audio);
    const imageUrl = computed(() => segment.value?.imageUrl);

    const headerData = computed(() =>
      getHeaderData(seo.value, { url: window.location.href })
    );

    const showModal = ref(false);

    onMounted(async () => {
      load({ contentType: "segment", slug: route.params?.slug });
    });

    useHead({
      title: computed(() => headerData.value?.title || "Illume"),
      meta: computed(() => headerData.value?.meta || []),
      link: computed(() => headerData.value?.link || []),
    });

    return {
      id: route.params.slug,
      segment,
      seo,
      artist,
      audio,
      route,
      headerData,
      showModal,
      imageUrl,
    };
  },
});

import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "author-card"
}
const _hoisted_2 = { class: "author-card__image-wrapper" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = { class: "author-card__information-wrapper" }
const _hoisted_5 = { class: "author-card__author-name" }
const _hoisted_6 = { class: "author-card__author-title" }
const _hoisted_7 = { class: "author-card__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazy = _resolveDirective("lazy")!

  return (_ctx.author)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("img", {
            class: "author-card__profile-image",
            alt: _ctx.profileImage.description
          }, null, 8, _hoisted_3), [
            [_directive_lazy, _ctx.profileImage.url]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.author.name), 1),
          _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.author.title), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.author.description), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}
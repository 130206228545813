export const segmentCollectionQuery = `
    items {
        title
        seo {
            metaTitle
            metaDescription
            metaImage {
            description
            url
            }
        }
        artist {
            name
            title
            description
            profileImage {
            description
            url
            }
        }
        year
        meditationType
        artworkImage {
            description
            url(transform: {
                format: JPG_PROGRESSIVE,
                quality: 80
            })
        }
        imageUrl
        audio {
            length
            audioTitle
            audio {
                description
                url
            }
            author {
                name
            }
        }
        slug
        artworkDescription
    }
`;

export const galleryCollectionQuery = `
    items {
        title
        slug
        seo {
            metaTitle
            metaDescription
            metaImage {
                description
                url
            }
        }
        headerImage {
            description
            url(transform: {
                format: JPG_PROGRESSIVE,
                quality: 80
            })
        }
        subTitle
        location
        description
        type
        primerTitle
        primerDescription
        primerAudio {
          author {
            name
          }
          audio {
            url
            description
          }
          audioTitle
          length
        }
        segmentsCollection(limit: 20) {
            items {
                title
                slug
                artworkImage {
                    description
                    url(transform: {
                        format: JPG_PROGRESSIVE,
                        quality: 80
                    })
                }
                artist {
                    name
                }
                year
                meditationType
                audio {
                    length
                author {
                    name
                }
                }
            }
        }
        authorsCollection(limit: 20) {
            items {
                title
                name
                description
                profileImage {
                    description
                    url
                }
            }
        }
    }
`;

export const homeCollectionQuery = `
    items {
        seo {
            metaTitle
            metaDescription
            metaImage {
                description
                url
            }
        }
        introAudio {
            audioTitle
            length
            audio {
                description
                url
            }
            author {
                name
            }
        }
        description
        galleryCollection(limit: 30) {
            items {
                title
                slug
                location
                headerImage {
                    url(transform: {
                        format: JPG_PROGRESSIVE,
                        width: 358,
                        height: 540,
                        resizeStrategy: FILL,
                        quality: 80
                    })
                    description
                }
                segmentsCollection {
                    total
                }
                authorsCollection {
                    total
                }
            }
        }
        homeAboutGalleryCollection(limit: 5) {
            items {
                title
                slug
                location
                headerImage {
                    url(transform: {
                        format: JPG_PROGRESSIVE,
                        width: 358,
                        height: 540,
                        resizeStrategy: FILL,
                        quality: 80
                    })
                    description
                }
                segmentsCollection {
                    total
                }
                authorsCollection {
                    total
                }
            }
        }
        comingSoonGalleryCollection(limit: 12) {
            items {
                title
                slug
                location
                headerImage {
                    url(transform: {
                        format: JPG_PROGRESSIVE,
                        width: 358,
                        height: 540,
                        resizeStrategy: FILL,
                        quality: 80
                    })
                    description
                }
                segmentsCollection {
                    total
                }
                authorsCollection {
                    total
                }
            }
        }
    }
`;

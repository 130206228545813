import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/static/icons/PLAY_BUTTON_LIGHT.svg'
import _imports_1 from '@/static/icons/PAUSE_BUTTON_DARK.svg'


const _hoisted_1 = {
  key: 0,
  class: "small-player"
}
const _hoisted_2 = { class: "small-player__main-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "play-button",
  src: _imports_0,
  alt: "play button"
}
const _hoisted_4 = {
  key: 1,
  class: "pause-button",
  src: _imports_1,
  alt: "play button"
}
const _hoisted_5 = { class: "small-player__primer-details-wrapper" }
const _hoisted_6 = { class: "small-player__title" }
const _hoisted_7 = { class: "small-player__details" }
const _hoisted_8 = {
  ref: "primerPlayer",
  preload: "metadata"
}
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.primer)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "small-player__button-wrapper",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.togglePlay && _ctx.togglePlay(...args)))
          }, [
            (_ctx.paused)
              ? (_openBlock(), _createElementBlock("img", _hoisted_3))
              : (_openBlock(), _createElementBlock("img", _hoisted_4))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.primerTitle), 1),
            _createElementVNode("span", _hoisted_7, [
              _createTextVNode(_toDisplayString(_ctx.primerLength) + " · With ", 1),
              _createElementVNode("strong", null, _toDisplayString(_ctx.primerAuthor), 1)
            ])
          ])
        ]),
        _createElementVNode("audio", _hoisted_8, [
          _createElementVNode("source", {
            src: _ctx.primerUrl,
            type: "audio/mp3"
          }, null, 8, _hoisted_9)
        ], 512)
      ]))
    : _createCommentVNode("", true)
}

import { defineComponent, computed, PropType } from "vue";
import { useRouter } from "vue-router";
import { SegmentProps } from "../../../composables/types";

export default defineComponent({
  name: "SegmentCard",
  props: {
    segment: Object as PropType<SegmentProps>,
  },
  setup(props) {
    const segmentImage = computed(() => props.segment?.artworkImage);

    const router = useRouter();

    const slug = computed(() => `/segment/${props.segment?.slug}`);

    const pushToSegment = () => {
      router.push({ path: slug.value });
    };

    return {
      segmentImage,
      pushToSegment,
      slug,
    };
  },
});

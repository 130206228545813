
import { defineComponent, computed, PropType } from "vue";
import { AuthorProps } from "../../../composables/types";

export default defineComponent({
  name: "AuthorCard",
  props: {
    author: Object as PropType<AuthorProps>,
  },
  setup(props) {
    const profileImage = computed(() => props.author?.profileImage);

    return {
      profileImage,
    };
  },
});


import { defineComponent, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { useContent } from "../../composables/useContent";
import SmallPlayer from "@/components/Player/SmallPlayer.vue";
import SegmentCard from "@/components/Segment/SegmentCard.vue";
import AuthorCard from "@/components/Author/AuthorCard.vue";
import { getHeaderData } from "@/components/SEO/helpers";
import { useHead } from "@vueuse/head";
import Markdown from "vue3-markdown-it";
import { GENERAL_FEEDBACK_URL } from "@/constants/feedback";
import { SUPPORT_US_URL } from "@/constants/support";

export default defineComponent({
  name: "GalleryPage",
  components: {
    SmallPlayer,
    SegmentCard,
    AuthorCard,
    Markdown,
  },
  setup() {
    const route = useRoute();
    const { load, result } = useContent();

    const galleryText =
      "Our guides bring extensive knowledge of art, museums and mindfulness to support your deep and slow looking experiences.";

    const aboutText =
      "Our hosts share their perspectives, and join in conversation with artists, scientists, meditation leaders and museum professionals to enhance your connection with works of art. ";

    const gallery = computed(() =>
      result.value?.id === "gallery" ? result.value : undefined
    );

    const seo = computed(() => gallery.value?.seo);

    const primerDescription = computed(() => gallery.value?.primerDescription);

    const primerProps = computed(() => gallery.value?.primerAudio);

    const segments = computed(() => gallery.value?.segmentsCollection?.items);

    const authors = computed(() => gallery.value?.authorsCollection?.items);

    const headerData = computed(() =>
      getHeaderData(seo.value, { url: window.location.href })
    );

    const contentHeading = computed(() =>
      gallery.value?.type === "About" ? "Learn" : "Artworks"
    );

    const authorHeading = computed(() =>
      gallery.value?.type === "About" ? "Hosts" : "Guides"
    );

    const authorText = computed(() =>
      gallery.value?.type === "About" ? aboutText : galleryText
    );

    onMounted(async () => {
      load({ contentType: "gallery", slug: route.params?.slug });
    });

    useHead({
      title: computed(() => headerData.value?.title || "Illume"),
      meta: computed(() => headerData.value?.meta || []),
      link: computed(() => headerData.value?.link || []),
    });

    return {
      id: route.params.slug,
      gallery,
      primerProps,
      primerDescription,
      segments,
      authors,
      authorHeading,
      authorText,
      contentHeading,
      GENERAL_FEEDBACK_URL,
      SUPPORT_US_URL,
    };
  },
});

<template>
  <div id="slider">
    <div ref="listenTo" class="progress-wrapper">
      <div :style="'width:' + progress + '%'" class="progress"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SeekProgress",
  props: {
    audioProgress: Number,
  },
  data() {
    return {
      progress: this.audioProgress,
      wrapperWidth: 0,
    };
  },
  mounted() {
    // this.$refs.listenTo.addEventListener("click", this.getClickPosition, false);
    // this.$refs.listenTo.addEventListener(
    //   "mousedown",
    //   this.detectMouseDown,
    //   false
    // );
    // this.$refs.listenTo.addEventListener("mouseup", this.detectMouseUp, false);

    //add a listener that will listen to window resize and modify progress width accordingly
    window.addEventListener("resize", this.windowResize, false);
  },
  methods: {
    // getClickPosition(e) {
    //   e = e || window.e;

    //   // get target element
    //   let target = e.target || e.srcElement;
    //   if (target.nodeType == 3) target = target.parentNode; // fix for a safari bug
    //   this.wrapperWidth = this.wrapperWidth || target.offsetWidth; // set initial progressbar width

    //   // get the seek width
    //   let seekWidth = e.offsetX;

    //   // change seek position
    //   this.progress = (seekWidth / this.wrapperWidth) * 100;
    //   this.$emit("changePosition", this.progress);
    // },
    // detectMouseDown(e) {
    //   e.preventDefault(); // prevent browser from moving objects, following links etc

    //   // start listening to mouse movements
    //   this.$refs.listenTo.addEventListener(
    //     "mousemove",
    //     this.getClickPosition,
    //     false
    //   );
    // },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // detectMouseUp(e) {
    //   // stop listening to mouse movements
    //   this.$refs.listenTo.removeEventListener(
    //     "mousemove",
    //     this.getClickPosition,
    //     false
    //   );
    // },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    windowResize(e) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let prog = this;
      setTimeout(() => {
        prog.wrapperWidth = prog.$refs?.listenTo?.offsetWidth;
      }, 200);
    },
  },
};
</script>

<style lang="scss">
.progress-wrapper {
  display: block;
  height: theme-spacing(1);
  position: relative;
  background: #e0e0e0;

  .progress {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #828282;
  }
}
</style>


import { defineComponent, ref, onUpdated } from "vue";
import { useRouter } from "vue-router";
import CustomModal from "@/components/Modal/CustomModal.vue";

export default defineComponent({
  name: "Header",
  components: {
    CustomModal,
  },
  props: {
    showHeaderIcons: Boolean,
  },
  setup() {
    const router = useRouter();

    const urlToCopy = ref(null);

    const showModal = ref(false);

    const url = ref(window.location.href);

    const navigateBack = () => {
      router.go(-1);
    };

    const goHome = () => {
      router.push({ path: "/" });
    };

    const copyUrl = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      urlToCopy.value?.focus();
      document.execCommand("copy");
    };

    onUpdated(() => {
      url.value = window.location.href;
    });

    return {
      navigateBack,
      goHome,
      showModal,
      url,
      urlToCopy,
      copyUrl,
    };
  },
});

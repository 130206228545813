import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createHead } from "@vueuse/head";
import VueLazyLoad from "vue3-lazyload";

const head = createHead();

createApp(App)
  .use(router)
  .use(head)
  .use(VueLazyLoad, {
    loading: "../img/HOME_HERO_GRADIENT.7e3c0b41.jpg",
  })
  .mount("#app");


import { defineComponent, computed, ref } from "vue";
export default defineComponent({
  name: "Small Player",
  props: {
    primer: Object,
  },
  setup(props) {
    const primerPlayer = ref<HTMLAudioElement | null>(null);

    const primerUrl = computed(() => props.primer?.audio?.url);
    const primerLength = computed(() => props.primer?.length);
    const primerTitle = computed(() => props.primer?.audioTitle);
    const primerAuthor = computed(() => props.primer?.author?.name);
    const paused = ref(true);

    const togglePlay = () => {
      if (primerPlayer.value?.paused) {
        primerPlayer.value?.play();
        paused.value = false;
      } else {
        primerPlayer.value?.pause();
        paused.value = true;
      }
    };

    return {
      primerUrl,
      primerPlayer,
      primerTitle,
      primerAuthor,
      primerLength,
      togglePlay,
      paused,
    };
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = { class: "content-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      showHeaderIcons: _ctx.showHeaderIcons,
      id: "header"
    }, null, 8, ["showHeaderIcons"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.path,
        id: "main-content"
      }))
    ]),
    _createVNode(_component_Footer, { id: "footer" })
  ]))
}
import {
  segmentCollectionQuery,
  galleryCollectionQuery,
  homeCollectionQuery,
} from "../config/queries";

export const selectQuery = (
  contentType: string,
  slug?: string | string[]
): string => {
  switch (contentType) {
    case "segment":
      return `{
        segmentCollection(where: { slug: "${slug}" }, limit: 1) {
          ${segmentCollectionQuery}
        }
      }`;
      break;
    case "gallery":
      return `{
        galleryCollection(where: { slug: "${slug}" }, limit: 1) {
          ${galleryCollectionQuery}
        }
      }`;
      break;
    case "home":
      return `{
          homeCollection(limit: 1) {
            ${homeCollectionQuery}
          }
        }`;
      break;
    default:
      return "";
  }
};

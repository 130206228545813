
import { defineComponent } from "vue";
import { VueFinalModal } from "vue-final-modal";

export default defineComponent({
  name: "CustomModal",
  inheritAttrs: false,
  components: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    VueFinalModal,
  },
});

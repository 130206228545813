
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
  onBeforeUnmount,
  computed,
} from "vue";
import { AudioProps } from "../../../composables/types";
import ProgressBar from "./SeekableSlider.vue";
// import CustomModal from "@/components/Modal/CustomModal.vue";
import { SEGMENT_FEEDBACK_URL } from "@/constants/feedback";

export default defineComponent({
  name: "LargePlayer",
  components: {
    ProgressBar,
    // CustomModal,
  },
  props: {
    player: Object as PropType<AudioProps>,
  },
  setup() {
    const playing = ref(false);
    const audioPlayer = ref<HTMLAudioElement | null>(null);
    const slider = ref(null);
    const paused = ref(true);
    const openModal = ref(false);

    const value = computed(() =>
      currentTime.value && duration.value
        ? (currentTime.value / duration.value) * 100
        : 0
    );

    const currentTime = ref<number | undefined>(0);
    const formattedCurrentTime = computed(() =>
      currentTime.value ? formatTime(currentTime.value) : "0:00"
    );

    const duration = ref<number | undefined>(0);
    const formattedDuration = computed(() =>
      duration.value ? formatTime(duration.value) : "0:00"
    );

    const formatTime = (time: number): string => {
      const min = Math.floor(time / 60);
      const sec = Math.floor(time % 60);
      return min + ":" + (sec < 10 ? "0" + sec : sec);
    };

    const showFeedback = () => {
      openModal.value = true;
    };

    onMounted(() => {
      audioPlayer.value?.addEventListener("timeupdate", () => {
        currentTime.value = audioPlayer.value?.currentTime;
      });
      audioPlayer.value?.addEventListener("loadedmetadata", () => {
        duration.value = audioPlayer.value?.duration;
      });
    });

    onBeforeUnmount(() => {
      audioPlayer.value?.removeEventListener(
        "timeupdate",
        () => (currentTime.value = audioPlayer.value?.currentTime)
      );
    });

    const togglePlay = () => {
      if (audioPlayer.value?.paused) {
        audioPlayer.value?.play();
        paused.value = false;
      } else {
        audioPlayer.value?.pause();
        paused.value = true;
      }
    };

    const updatePosition = (newPosition: number) => {
      const newPositionInSeconds = duration.value
        ? Math.floor((newPosition / 100) * duration.value)
        : 0;
      if (
        audioPlayer.value?.currentTime &&
        audioPlayer.value?.currentTime >= 0
      ) {
        audioPlayer.value.currentTime = newPositionInSeconds;
      }
    };

    return {
      playing,
      audioPlayer,
      togglePlay,
      paused,
      value,
      duration,
      slider,
      formattedDuration,
      formattedCurrentTime,
      updatePosition,
      showFeedback,
      openModal,
      SEGMENT_FEEDBACK_URL,
    };
  },
});


import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "App",
  components: {
    Footer,
    Header,
  },
  setup() {
    const route = useRoute();
    const showHeaderIcons = computed(
      () =>
        route.fullPath.startsWith("/gallery/") ||
        route.fullPath.startsWith("/segment/")
    );

    return {
      showHeaderIcons,
    };
  },
});
